/**
 * @fileOverview - Design Token の Typography に基づく実装
 * {@link https://www.figma.com/design/2EE89VT65cWVU9KOR0j9ph/Design-System-v0.2.0?node-id=1-5}
 */
import React from "react"

import { customTwMerge } from "@/lib/utils"

type Props<T extends React.ElementType> = {
  variant:
    | "display-lg"
    | "display-md"
    | "display-sm"
    | "headline-xl"
    | "headline-lg"
    | "headline-md"
    | "headline-sm"
    | "title-lg"
    | "title-md"
    | "title-sm"
    | "title-xs"
    | "body-lg"
    | "body-md"
    | "body-sm"
    | "body-xs"
  as: T
} & React.ComponentPropsWithoutRef<T>

export const Typography = React.forwardRef(
  <T extends React.ElementType>(
    { as, className, variant, children, ...props }: Props<T>,
    ref: React.Ref<Element>
  ) => {
    return React.createElement(
      as,
      {
        ...props,
        ref,
        className: customTwMerge(
          `typography-${variant}`,
          "text-design-tokens-object-base-high",
          className
        ),
      },
      children
    )
  }
)

Typography.displayName = "Typography"
