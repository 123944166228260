export const designTokens = {
  "color": {
    "base": {
      "white": "#FFFFFF",
      "black": "#000000"
    },
    "turquoise": {
      "100": "#F1F8FA",
      "200": "#D2E9F1",
      "300": "#9AD2E3",
      "400": "#5DB7CF",
      "500": "#0099B5",
      "600": "#007C98",
      "700": "#08647E",
      "800": "#134A5F",
      "900": "#123648"
    },
    "stone": {
      "100": "#F6F5F4",
      "200": "#ECEBEA",
      "300": "#D7D4D1",
      "400": "#ADA8A3",
      "500": "#857E78",
      "600": "#615B57",
      "700": "#46413F",
      "800": "#2F2B29",
      "900": "#1E1B19"
    },
    "amber": {
      "50": "#FFFBEB",
      "100": "#FEF3C7",
      "200": "#FDE68A",
      "300": "#FCD34D",
      "400": "#FBBF24",
      "500": "#F59E0B",
      "600": "#D97706",
      "700": "#B45309",
      "800": "#92400E",
      "900": "#78350F"
    },
    "red": {
      "50": "#FEF2F2",
      "100": "#FEE2E2",
      "200": "#FECACA",
      "300": "#FCA5A5",
      "400": "#F87171",
      "500": "#EF4444",
      "600": "#DC2626",
      "700": "#B91C1C",
      "800": "#991B1B",
      "900": "#7F1D1D"
    },
    "green": {
      "50": "#F0FDF4",
      "100": "#DCFCE7",
      "200": "#BBF7D0",
      "300": "#86EFAC",
      "400": "#4ADE80",
      "500": "#22C55E",
      "600": "#16A34A",
      "700": "#15803D",
      "800": "#166534",
      "900": "#14532D"
    },
    "object": {
      "base": {
        "high": {
          "DEFAULT": "var(--color-stone-900)",
          "inverse": "var(--color-base-white)"
        },
        "mid": {
          "DEFAULT": "#1E1B199E",
          "inverse": "#FFFFFFAD"
        },
        "low": {
          "DEFAULT": "#1E1B1952",
          "inverse": "#FFFFFF61"
        }
      },
      "accent": {
        "dim": "var(--color-turquoise-600)",
        "bright": "var(--color-turquoise-500)"
      },
      "error": {
        "dim": "var(--color-red-700)",
        "bright": "var(--color-red-600)"
      },
      "caution": {
        "dim": "var(--color-amber-700)",
        "bright": "var(--color-amber-600)"
      },
      "success": {
        "dim": "var(--color-green-700)",
        "bright": "var(--color-green-600)"
      }
    },
    "surface": {
      "base": {
        "primary": {
          "DEFAULT": "var(--color-base-white)",
          "inverse": "var(--color-stone-900)"
        },
        "secondary": {
          "DEFAULT": "var(--color-stone-100)",
          "inverse": "var(--color-stone-800)"
        },
        "tertiary": {
          "DEFAULT": "var(--color-stone-200)",
          "inverse": "var(--color-stone-700)"
        }
      },
      "overlay": {
        "modal": "#1E1B1961",
        "hovered": {
          "DEFAULT": "#1E1B190F",
          "inverse": "#FFFFFF0F"
        },
        "selected": "#0099B50F",
        "scrollbar": "#1E1B1940"
      },
      "accent": {
        "dim": "var(--color-turquoise-600)",
        "bright": "var(--color-turquoise-500)",
        "subtle": "var(--color-turquoise-100)"
      },
      "success": {
        "dim": "var(--color-green-700)",
        "bright": "var(--color-green-600)",
        "subtle": "var(--color-green-50)"
      },
      "caution": {
        "dim": "var(--color-amber-700)",
        "bright": "var(--color-amber-600)",
        "subtle": "var(--color-amber-50)"
      },
      "error": {
        "dim": "var(--color-red-700)",
        "bright": "var(--color-red-600)",
        "subtle": "var(--color-red-50)"
      }
    },
    "border": {
      "base": {
        "high": {
          "DEFAULT": "var(--color-stone-900)",
          "inverse": "var(--color-base-white)"
        },
        "low": {
          "DEFAULT": "#1E1B191F",
          "inverse": "#FFFFFF38"
        }
      },
      "accent": {
        "dim": "var(--color-turquoise-600)",
        "bright": "var(--color-turquoise-500)"
      },
      "error": {
        "dim": "var(--color-red-700)",
        "bright": "var(--color-red-600)"
      },
      "caution": {
        "dim": "var(--color-amber-700)",
        "bright": "var(--color-amber-600)"
      },
      "success": {
        "dim": "var(--color-green-700)",
        "bright": "var(--color-green-600)"
      }
    },
    "display": {
      "intent": {
        "high": {
          "dim": "var(--color-red-700)",
          "subtle": "var(--color-red-50)"
        },
        "mid": {
          "dim": "var(--color-amber-700)",
          "subtle": "var(--color-amber-50)"
        },
        "low": {
          "dim": "var(--color-green-700)",
          "subtle": "var(--color-green-50)"
        }
      },
      "brand": {
        "infobox": "var(--color-brand-turquoise)",
        "x": "#000000",
        "facebook": "#0866FF",
        "linkedin": "#2867B2"
      }
    },
    "brand": {
      "turquoise": "#03A4C1",
      "black": "#3E3A39",
      "white": "#FFFFFF",
      "stone": "#F5F5F4"
    }
  },
  "tracking": {
    "tight": "-0.025em",
    "normal": "0em",
    "wide": "0.025em"
  }
};