import { designTokens } from "design-tokens"
import type { Config } from "tailwindcss"

// const defaultTheme = require("tailwindcss/defaultTheme")

const config = {
  darkMode: ["class"],
  content: [
    "./pages/**/*.{ts,tsx}",
    "./components/**/*.{ts,tsx,js,jsx}",
    "./app/**/*.{ts,tsx,js,jsx,mdx}",
  ],
  theme: {
    // tailwindui start
    fontSize: {
      xs: ["0.75rem", { lineHeight: "1rem" }],
      sm: ["0.875rem", { lineHeight: "1.5rem" }],
      base: ["1rem", { lineHeight: "1.75rem" }],
      lg: ["1.125rem", { lineHeight: "2rem" }],
      xl: ["1.25rem", { lineHeight: "2rem" }],
      "2xl": ["1.5rem", { lineHeight: "2rem" }],
      "3xl": ["2rem", { lineHeight: "2.5rem" }],
      "4xl": ["2.5rem", { lineHeight: "3.5rem" }],
      "5xl": ["3rem", { lineHeight: "3.5rem" }],
      "6xl": ["3.75rem", { lineHeight: "1" }],
      "7xl": ["4.5rem", { lineHeight: "1.1" }],
      "8xl": ["6rem", { lineHeight: "1" }],
      "9xl": ["8rem", { lineHeight: "1" }],
    },
    fontFamily: {
      poppins: ["var(--font-poppins)"],
      inter: ["var(--font-inter)"],
    },
    // tailwindui end
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      borderWidth: {
        3: "3px",
      },
      backgroundImage: ({ theme }) => ({
        "gradient-to-top-background-secondary": `linear-gradient(to top,
        ${theme("colors.tokens.background.secondary")},
        ${theme("colors.tokens.background.default")})`,
        "gradient-to-top-background-tertiary": `linear-gradient(to top,
        ${theme("colors.tokens.background.tertiary")},
        ${theme("colors.tokens.background.default")})`,
      }),
      colors: {
        ["design-tokens"]: {
          ...designTokens.color,
        },
        // TODO: 上記の新しいデザイントークンに移行が完了したら下記の tokens は削除する
        tokens: {
          ui: {
            primary: {
              DEFAULT: "var(--ui-primary)",
            },
            secondary: {
              DEFAULT: "var(--ui-secondary)",
            },
          },
          "third-party": {
            facebook: "var(--third-party-facebook)",
            "linked-in": "var(--third-party-linked-in)",
            x: "var(--third-party-x)",
          },
          background: {
            default: "var(--background-default)",
            "default-inverse": "var(--background-default-inverse)",
            primary: "var(--background-primary)",
            secondary: "var(--background-secondary)",
            tertiary: "var(--background-tertiary)",
            overlay: "var(--background-overlay)",
          },
          highlight: {
            error: "var(--highlight-error)",
            select: "var(--highlight-select)",
          },
          border: {
            "low-emphasis": {
              DEFAULT: "var(--border-low-emphasis)",
            },
            "medium-emphasis": {
              DEFAULT: "var(--border-medium-emphasis)",
            },
            "high-emphasis": {
              DEFAULT: "var(--border-high-emphasis)",
            },
            error: {
              DEFAULT: "var(--system-error)",
            },
            "accent-primary": {
              DEFAULT: "var(--border-accent-primary)",
            },
            "ui-primary": {
              DEFAULT: "var(--border-ui-primary)",
            },
          },
          object: {
            "high-emphasis": "var(--object-high-emphasis)",
            "medium-emphasis": "var(--object-medium-emphasis)",
            disable: "var(--object-disable)",
            "caution-dim": "var(--object-caution-dim)",
            "error-dim": "var(--object-error-dim)",
            "success-dim": "var(--object-success-dim)",
            "accent-dim": "var(--object-accent-dim)",
          },
          surface: {
            "caution-subtle": "var(--surface-caution-subtle)",
            "error-subtle": "var(--surface-error-subtle)",
            "success-subtle": "var(--surface-success-subtle)",
          },
        },
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
      },
      letterSpacing: {
        ...designTokens.tracking,
      },
      textColor: {
        tokens: {
          "high-emphasis": {
            DEFAULT: "var(--text-high-emphasis)",
          },
          "high-emphasis-inverse": {
            DEFAULT: "var(--text-high-emphasis-inverse)",
          },
          "medium-emphasis": {
            DEFAULT: "var(--text-medium-emphasis)",
          },
          "low-emphasis": {
            DEFAULT: "var(--text-low-emphasis)",
          },
          "ui-primary": {
            DEFAULT: "var(--ui-primary)",
          },
          error: {
            DEFAULT: "var(--system-error)",
          },
        },
      },
      dropShadow: {
        lv1: "var(--drop-shadow-lv1)",
        lv2: "var(--drop-shadow-lv2)",
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
        "4xl": "2rem",
      },
      fontSize: {
        "token-3xs": "var(--font-size-3xs)",
        "token-2xs": "var(--font-size-2xs)",
        "token-xs": "var(--font-size-xs)",
        "token-sm": "var(--font-size-sm)",
        "token-base": "var(--font-size-base)",
        "token-xl": "var(--font-size-xl)",
        "token-lg": "var(--font-size-lg)",
        "token-2xl": "var(--font-size-2xl)",
      },
      lineHeight: {
        "token-5": "var(--leading-5)",
        "token-6": "var(--leading-6)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      height: {
        13: "3.25rem",
      },
      boxShadow: {
        "element-switch": "var(--effect-shadow-element-switch)",
      },
    },
  },
  plugins: [require("tailwindcss-animate")],
} satisfies Config

export default config
