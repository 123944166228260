import { clsx, type ClassValue } from "clsx"
import { extendTailwindMerge, twMerge } from "tailwind-merge"

import config from "../tailwind.config"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function customTwMerge(...inputs: ClassValue[]) {
  const getExtendThemeTextColor = () => {
    const textColorTokens = config.theme.extend.textColor.tokens
    if (textColorTokens) {
      return Object.keys(textColorTokens).map((key) => `text-tokens-${key}`)
    }
  }
  const getExtendThemeFontSize = () => {
    const textColorTokens = config.theme.extend.fontSize
    if (textColorTokens) {
      return Object.keys(textColorTokens).map((key) => `text-${key}`)
    }
  }
  return extendTailwindMerge({
    extend: {
      classGroups: {
        "text-color": getExtendThemeTextColor() || [],
        "font-size": getExtendThemeFontSize() || [],
      },
    },
  })(clsx(inputs))
}
